import React, { useState, useEffect, useRef } from 'react';
import Portrait from "../assets/portrait.jpg";
import Mail from "../assets/envelope-regular.svg";
import LinkedIn from "../assets/linkedin.svg";

function About() {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { rootMargin: "0px",
      threshold: 0.5 }
    );

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [isIntersecting]);

  useEffect(() => {
    if (isIntersecting) {
      ref.current.classList.remove("opacity-[0.0000001]")
      ref.current.classList.add("opacity-100");
      ref.current.classList.add("animate-fadeInUp");
      };
    }
  , [isIntersecting]);

  return (
    <div ref={ref} className="flex flex-col items-center w-full opacity-[0.0000001] mt-10 bg-[#662D91] lg:flex-row lg:justify-around">
      <div className="flex flex-col items-center w-full lg:w-[50%] 2xl:w-[40%]">
        <h1 id="about" className="text-2xl mb-5 mt-10 lg:text-4xl lg:my-10">
          About Me
        </h1>
        <img
          className="rounded-full w-[80%] mb-5 lg:w-[40%] 2xl:w-[30%]"
          src={Portrait}
          alt="Portrait of Ole Ragnar Randen"
        />
        <div className="flex flex-row justify-between w-[60%] mb-5 lg:w-[30%] lg:mb-10">
          <a
          target="blank"
            className="flex flex-row justify-between w-[20%]"
            href="https://www.linkedin.com/in/or-randen/"
          >
            <img src={LinkedIn} alt="" />
          </a>
          <a
            className="flex flex-row justify-between w-[25%]"
            href="mailto:or.randen@gmail.com"
          >
            <img src={Mail} alt="" />
          </a>
        </div>
      </div>
      <div className="flex flex-col justify-center w-[80%] mb-5 lg:w-[50%] lg:my-10 lg:text-xl lg:text-justify 2xl:w-[40%]">
        <p className="flex justify-center mb-5 text-xl lg:block lg:text-center lg:w-[80%]">Hello hello</p>
        <p className="mb-5 lg:w-[80%]">
          I'm Ole Ragnar Randen, a 23 year old web developer from Heidal.
        </p>
        <p className="mb-5 lg:w-[80%]">
          Currently about to complete my bachelor's degree in web development at
          NTNU.
        </p>
        <p className="mb-5 lg:w-[80%]">
          While I appreciate the creativity and visual aspects of frontend
          development, I have recently found myself drawn to the challenges of
          backend development.
        </p>
        <p className="mb-5 lg:w-[80%]">
          Having gained practical experience through various projects during my
          studies, I am now actively seeking employment opportunities to apply
          my skills and contribute to real-world projects.
        </p>
      </div>
    </div>
  );
}

export default About;
