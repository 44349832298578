import React, { useState, useEffect, useRef } from "react";
import UpArrow from "../assets/UpWhite.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from '@emailjs/browser';

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    subject: "",
    email: "",
    message: "",
  });

  var templateParams = {
    subject: formData.subject,
    name: formData.name,
    email: formData.email,
    message: formData.message,
};

  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);

  const form = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { rootMargin: "0px", threshold: 0.5 }
    );

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [isIntersecting]);

  useEffect(() => {
    if (isIntersecting) {
      ref.current.classList.remove("opacity-[0.0000001]");
      ref.current.classList.add("opacity-100");
      ref.current.classList.add("animate-fadeInUp");
    }
  }, [isIntersecting]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("Sending Email");
      emailjs.send("service_cwbnm63","template_l8tmwsr", templateParams, "vTo2eXyweAnDWTmiU",{});
      console.log("Email sent successfully!");
      toast.success("Message sent successfully", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored", 
      }); 
      // Reset the form
      setFormData({
        name: "",
        subject: "",
        email: "",
        message: "",
      });
    } catch (error) {
      console.log("Failed to send email:", error);
      toast.error('Something went wrong', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
  };

  return (
    <div
      ref={ref}
      className=" flex flex-col items-center opacity-[0.0000001] w-full mt-10 bg-[#662D91] lg:mt-20"
    >
      <h1 id="background" className="text-2xl my-5 mt-10 lg:text-4xl lg:my-10">
        Contact Me
      </h1>
      <div className="mt-5 mb-10 text-black w-[85%] rounded-md bg-[#DBC2CF] p-4 lg:w-[50%] 2xl:w-[30%]">
        <form className="w-full" onSubmit={handleSubmit}>
          <label htmlFor="name">Name</label>
          <br />
          <input
            className="w-full p-2 rounded-sm mt-1"
            type="text"
            id="name"
            placeholder="Kari Nordmann"
            required
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
          <br />
          <br />

          <label htmlFor="subject">Subject</label>
          <br />
          <input
            className="w-full p-2 rounded-sm mt-1"
            type="text"
            id="subject"
            placeholder="How are you?"
            required
            value={formData.subject}
            onChange={(e) =>
              setFormData({ ...formData, subject: e.target.value })
            }
          />
          <br />
          <br />

          <label htmlFor="email">Email</label>
          <br />
          <input
            className="w-full p-2 rounded-sm mt-1"
            type="email"
            id="email"
            placeholder="kari-nord@yahoo.com"
            required
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
          <br />
          <br />

          <label htmlFor="message">Message</label>
          <br />
          <textarea
            className="w-full p-2 rounded-sm resize-none mt-1"
            id="message"
            rows="4"
            placeholder="Hello, my name is Kari and I was just wondering how you were doing :)"
            required
            value={formData.message}
            onChange={(e) =>
              setFormData({ ...formData, message: e.target.value })
            }
          />
          <br />
          <br />

          <input
            className="w-full border-solid px-3 py-3 bg-[#3a1b52] text-white rounded-lg"
            type="submit"
            value="Submit"
          />
        </form>
      </div>
      <div className="text-xl mb-5 w-[40%] lg:my-10 lg:w-[25%] 2xl:w-[18%]">
        <a href="#top" className="flex flex-col items-center">
          <img
            src={UpArrow}
            alt=""
            className="animate-twBounce animate-infinite mt-5 w-[35%] 2xl:w-[20%]"
          />
          <h2 className="lg:text-3xl">Scroll to top</h2>
        </a>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}

export default Contact;
