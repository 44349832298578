import React, { useState, useEffect, useRef } from 'react';

function Background() {

  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { rootMargin: "0px",
     threshold: 0.5 }
    );

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [isIntersecting]);

  useEffect(() => {
    if (isIntersecting) {
      ref.current.classList.remove("opacity-[0.0000001]")
      ref.current.classList.add("opacity-100");
      ref.current.classList.add("animate-fadeInUp");
      };
    }
  , [isIntersecting]);

  return (

    <div ref={ref} className="flex flex-col items-center opacity-[0.0000001] w-full mt-10 bg-[#662D91] lg:mt-20">
      <h1 id="background" className="text-2xl mb-5 mt-10 lg:text-4xl lg:my-10">
        Background
      </h1>
      <div className="flex flex-col items-center w-[80%] mb-5 2xl:w-[70%]">
        <h2 className="text-xl mb-3 lg:text-xl">2020 - 2023</h2>
        <h3 className="mb-3 lg:text-xl">Bachelor in Web Development</h3>
        <hr className="w-[75%] h-[1px] mx-auto my-10 bg-[#CAA5B8] border-0 rounded-md" />
        <h1 id="background" className="text-2xl mb-5 lg:text-2xl lg:my-10">
          Skillset
        </h1>
        <div id="techBox" className="flex flex-row justify-between w-full text-xl lg:w-[50%] 2xl:w-[30%]">
          <div className="flex flex-col items-center w-[30%]">
            <a href="https://developer.mozilla.org/en-US/docs/Web/HTML" target="blank"><div className="flex flex-col items-center mb-3 lg:hover:scale-110 duration-[250ms]">
              <i className="devicon-html5-plain text-4xl"></i>
              <p>HTML</p>
            </div></a>
            <a href="https://developer.mozilla.org/en-US/docs/Web/CSS" target="blank"><div className="flex flex-col items-center mb-3 lg:hover:scale-110 duration-[250ms]">
              <i className="devicon-css3-plain text-4xl"></i>
              <p>CSS</p>
            </div></a>
            <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript" target="blank"><div className="flex flex-col items-center mb-3 lg:hover:scale-110 duration-[250ms]">
              <i className="devicon-javascript-plain text-4xl"></i>
              <p>JavaScript</p>
            </div></a>
            <a href="https://react.dev" target="blank"><div className="flex flex-col items-center mb-3 lg:hover:scale-110 duration-[250ms]">
              <i className="devicon-react-original text-4xl"></i>
              <p>React</p>
            </div></a>
          </div>
          <div className="flex flex-col items-center w-[30%]">
          <a href="https://www.mongodb.com" target="blank"><div className="flex flex-col items-center mb-3 lg:hover:scale-110 duration-[250ms]">
              <i className="devicon-mongodb-plain text-4xl"></i>
              <p>MongoDB</p>
            </div></a>
            <a href="https://expressjs.com" target="blank"><div className="flex flex-col items-center mb-3 lg:hover:scale-110 duration-[250ms]">
              <i className="devicon-express-original text-4xl"></i>
              <p>Express.js</p>
            </div></a>
            <a href="https://nodejs.org/en" target="blank"><div className="flex flex-col items-center mb-3 lg:hover:scale-110 duration-[250ms]">
              <i className="devicon-nodejs-plain text-4xl"></i>
              <p>Node.js</p>
            </div></a>
            <a href="https://www.php.net" target="blank"><div className="flex flex-col items-center mb-3 lg:hover:scale-110 duration-[250ms]">
              <i className="devicon-php-plain text-4xl"></i>
              <p>PHP</p>
            </div></a>
          </div>
          <div className="flex flex-col items-center w-[30%]">
          <a href="https://www.mysql.com" target="blank"><div className="flex flex-col items-center mb-3 lg:hover:scale-110 duration-[250ms]">
              <i className="devicon-mysql-plain text-4xl"></i>
              <p>MySQL</p>
            </div></a>
            <a href="https://github.com" target="blank"><div className="flex flex-col items-center mb-3 lg:hover:scale-110 duration-[250ms]">
              <i className="devicon-github-original text-4xl"></i>
              <p>GitHub</p>
            </div></a>
            <a href="https://www.figma.com" target="blank"><div className="flex flex-col items-center mb-3 lg:hover:scale-110 duration-[250ms]">
              <i className="devicon-figma-plain text-4xl"></i>
              <p>Figma</p>
            </div></a>
            <a href="https://code.visualstudio.com" target="blank"><div className="flex flex-col items-center mb-3 lg:hover:scale-110 duration-[250ms]">
              <i className="devicon-vscode-plain text-4xl"></i>
              <p>VSCode</p>
            </div></a>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Background;
