import { useState } from "react";
import Logo from "../assets/logoORR.png";
import LogoWhite from "../assets/logoORRwhite.png";
import About from "../components/About.jsx";
import Background from "../components/Background.jsx";
import Projects from "../components/Projects.jsx";
import Contact from "../components/Contact.jsx";

function Landing() {
  const [isNavOpen, setIsNavOpen] = useState(false); // initiate isNavOpen state with false

  function disableScroll() {
    if (!isNavOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }

  return (
    <div id="mainpage" className="flex flex-col items-center overflow-hidden">
      <div className="flex items-center justify-between border-b border-gray-400 bg-[#662D91] py-2 px-4">
        <a className="w-[20%] lg:w-[5%]" href="/">
          <img
            className="my-4 animate-rubberBand animate-delay-2s"
            src={LogoWhite}
            alt="logo"
          />
        </a>
        <nav className="lg:w-[50%]">
          <section className="MOBILE-MENU flex lg:hidden">
            <div
              className="HAMBURGER-ICON space-y-2"
              onClick={() => setIsNavOpen(true) + disableScroll()}
            >
              <span className="block h-0.5 w-8 animate-pulse bg-white"></span>
              <span className="block h-0.5 w-8 animate-pulse bg-white"></span>
              <span className="block h-0.5 w-8 animate-pulse bg-white"></span>
            </div>

            <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
              <div
                className="CROSS-ICON absolute top-0 right-0 px-8 py-8"
                onClick={() => setIsNavOpen(false) + disableScroll()}
              >
                <svg
                  className="h-8 w-8 text-white"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              </div>
              <ul className="MENU-LINK-MOBILE-OPEN flex flex-col items-center justify-between min-h-[250px]">
                <li className="border-b border-gray-400 my-8 uppercase">
                  <a
                    onClick={() => setIsNavOpen(false) + disableScroll()}
                    href="#about"
                  >
                    About
                  </a>
                </li>
                <li className="border-b border-gray-400 my-8 uppercase">
                  <a
                    onClick={() => setIsNavOpen(false) + disableScroll()}
                    href="#background"
                  >
                    Background
                  </a>
                </li>
                <li className="border-b border-gray-400 my-8 uppercase">
                  <a
                    onClick={() => setIsNavOpen(false) + disableScroll()}
                    href="#projects"
                  >
                    Projects
                  </a>
                </li>
                <li className="border-b border-gray-400 my-8 uppercase">
                  <a
                    onClick={() => setIsNavOpen(false) + disableScroll()}
                    href="#contact"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </section>

          <ul className="DESKTOP-MENU hidden space-x-8 lg:flex lg:w-[100%] lg:justify-around lg:text-xl 2xl:text-2xl">
            <li>
              <a
                onClick={() => setIsNavOpen(false)}
                href="#about"
              >
                About
              </a>
            </li>
            <li>
              <a
                onClick={() => setIsNavOpen(false)}
                href="#background"
              >
                Background
              </a>
            </li>
            <li>
              <a
                onClick={() => setIsNavOpen(false)}
                href="#projects"
              >
                Projects
              </a>
            </li>
            <li>
              <a
                onClick={() => setIsNavOpen(false)}
                href="#contact"
              >
                Contact
              </a>
            </li>
          </ul>
        </nav>
        <style>{`
      .hideMenuNav {
        display: none;
      }
      .showMenuNav {
        display: block;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background: #662D91;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
    `}</style>
      </div>
      <div className="flex flex-row justify-center w-full text-center mt-10 text-black lg:my-20">
        <h1 className="text-3xl mx-5 lg:text-6xl animate-jackInTheBox">
          Welcome
        </h1>
      </div>
      <div id="about"></div>
      <About />
      <div id="background"></div>
      <Background />
      <div id="projects"></div>
      <Projects />
      <div id="contact"></div>
      <Contact />
    </div>
  );
}

export default Landing;
