import React, { useState, useEffect, useRef } from 'react';
import Project1 from "../assets/project_1.jpg";
import Project2 from "../assets/project_2.jpg";
import Project3 from "../assets/project_3.png";
import Maria from "../assets/Maria Sæther.webp";
import Sivert from "../assets/Sivert Grønli Amundsen.webp";
import Kriss from "../assets/Kristoffer_Sinkaberg_Johansen.webp";
import Mons from "../assets/Mons Stenbråten.webp";


function Projects() {

  
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { rootMargin: "0px",
     threshold: 0.0 }
    );

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [isIntersecting]);

  useEffect(() => {
    if (isIntersecting) {
      ref.current.classList.remove("opacity-[0.0000001]")
      ref.current.classList.add("opacity-100");
      ref.current.classList.add("animate-fadeInUp");
      ref.current.classList.add("animate-slow");
      };
    }
  , [isIntersecting]);



  return (

    <div ref={ref} className=" flex flex-col items-center opacity-[0.0000001] w-full mt-10 bg-[#662D91] lg:mt-20">
      <h1 id="projects" className="text-2xl mb-5 mt-10 lg:text-4xl">
        Projects
      </h1>
      <div className='flex flex-col items-center w-full lg:flex-row lg:items-start lg:justify-between lg:w-[95%] 2xl:w-[80%]'>
      <div className="flex flex-col items-center py-5 my-5 w-[85%] text-black bg-[#DBC2CF] rounded-lg lg:my-10 lg:w-[30%]">
        <h2 className="text-xl font-bold mb-5 lg:text-xl lg:my-10">
          Avgangsutstilling.no
        </h2>
        <img className="mb-5 lg:mb-10" src={Project2} />
        <div className='flex flex-col items-center lg:h-[250px] lg:overflow-y-scroll lg:w-[90%] lg:bg-[#ffffff] lg:rounded lg:mb-10'>
        <p className="w-[80%] mb-5 lg:m-2">
          At the end of our bachelor's program, we were required to have a
          graduation exhibition to showcase our work. This exhibition had a
          website that I contributed to creating.
        </p>
        <p className="w-[80%] mb-5 lg:m-2">
          My main responsibility was to gather data from students in the BMED,
          BWU, and BIXD programs. I automated the data collection process using
          Microsoft Forms, Power Automate, and Teams as a pseudo-database.
        </p>
        <p className="w-[80%] mb-5 lg:m-2">
          I was also involved in quality assurance of the collected data before
          its implementation into the project. The website was a collaborative
          effort among all three programs.
        </p>
        </div>
        <hr className="w-[75%] h-[1px] mx-auto mb-10 bg-[#CAA5B8] border-0 rounded-md" />
        <a className="mb-10" href="https://avgangsutstilling.no" target="blank"><button className=" border-solid px-3 py-3 bg-[#3a1b52] text-white rounded-full lg:hover:scale-110 duration-[250ms]">Visit Avgangsutstilling.no</button></a>
      </div>
      <div className="flex flex-col items-center py-5 mt-5 w-[85%] text-black bg-[#DBC2CF] rounded-lg lg:my-10 lg:w-[30%]">
        <h2 className="text-xl font-bold mb-5 lg:text-xl lg:my-10">
          Smart Fridge
        </h2>
        <img className="mb-5 lg:mb-10" src={Project1} />
        <div className='flex flex-col items-center lg:h-[250px] lg:overflow-y-scroll lg:w-[90%] lg:bg-[#ffffff] lg:rounded lg:mb-10'>
        <p className="w-[80%] mb-5 lg:m-2">
          In the subject "Web of Things", myself and the rest of the team I was
          a part of created a website with the aim of reducing food waste.{" "}
        </p>
        <p className="w-[80%] mb-5 lg:m-2">
          The concept of Smart Fridge involved scanning items before placing
          them into the pantry and refrigerator to keep track of the ingredients
          at home.
        </p>
        <p className="w-[80%] mb-5 lg:m-2">
          The scanned items were added to a database that the website accessed
          to provide an overview in the form of a list. You could then check
          this list on your mobile phone while you were out shopping.
        </p>
        <p className="w-[80%] mb-5 lg:m-2">
          This way, you could avoid ending up with 5 partially eaten, spicy Tex
          Mex Taco sauces in the refrigerator.
        </p>
        <p className="w-[80%] mb-5 lg:m-2">
          We developed a proof of concept (POC) for the exhibition in our
          course. For the POC, we used MongoDB, Express, Node.js, EJS, and
          Mongoose.
        </p>
        </div>
        <hr className="w-[75%] h-[1px] mx-auto mb-10 bg-[#CAA5B8] border-0 rounded-md" />
        <div className="flex flex-col items-center w-[100%] mb-11">
          <p className="text-lg font-semibold mb-5">Tech</p>
          <div
            id="techBox"
            className="flex flex-col w-[100%] justify-between text-lg"
          >
            <div className="flex flex-row justify-around mb-5 w-full">
              <a
                href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
                target="blank"
              >
                <div className="flex flex-col items-center mb-3 lg:hover:scale-110 duration-[250ms]">
                  <i className="devicon-javascript-plain text-4xl lg:text-2xl"></i>
                  <p>JavaScript</p>
                </div>
              </a>
              <a href="https://www.mongodb.com" target="blank">
                <div className="flex flex-col items-center mb-3 lg:hover:scale-110 duration-[250ms]">
                  <i className="devicon-mongodb-plain text-4xl lg:text-2xl"></i>
                  <p>MongoDB</p>
                </div>
              </a>
              <a href="https://expressjs.com" target="blank">
                <div className="flex flex-col items-center mb-3 lg:hover:scale-110 duration-[250ms]">
                  <i className="devicon-express-original text-4xl lg:text-2xl"></i>
                  <p>Express.js</p>
                </div>
              </a>
            </div>
            <div className="flex flex-row justify-around">
              <a href="https://nodejs.org/en" target="blank">
                <div className="flex flex-col items-center mb-3 lg:hover:scale-110 duration-[250ms]">
                  <i className="devicon-nodejs-plain text-4xl lg:text-2xl"></i>
                  <p>Node.js</p>
                </div>
              </a>
              <a href="https://www.figma.com" target="blank">
                <div className="flex flex-col items-center mb-3 lg:hover:scale-110 duration-[250ms]">
                  <i className="devicon-figma-plain text-4xl lg:text-2xl"></i>
                  <p>Figma</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <hr className="w-[75%] h-[1px] mx-auto mb-10 bg-[#CAA5B8] border-0 rounded-md" />
        <div className="flex flex-col items-center w-[100%] mb-10 lg:w-[75%]">
          <p className="text-lg font-semibold mb-10">Collaborators</p>
          <div className="flex flex-col w-[100%]">
            <div className="w-full flex flex-row mb-10">
              <a
                className="w-full ml-5 mr-[10px]"
                href="https://www.msuix.com"
                target="blank"
              >
                <img
                  className="w-full opacity-100 rounded-lg transition-all ease-in-out delay-50 lg:hover:-translate-y-2 lg:hover:scale-110 lg:hover:shadow-lg lg:hover:shadow-black duration-[250ms]"
                  src={Maria}
                  alt=""
                />
              </a>
              <a
                className="w-full mr-5 ml-[10px]"
                href="https://www.sinka.no"
                target="blank"
              >
                <img
                  className="w-full opacity-100 rounded-lg transition-all ease-in-out delay-50 lg:hover:-translate-y-2 lg:hover:scale-110 lg:hover:shadow-lg lg:hover:shadow-black duration-[250ms]"
                  src={Kriss}
                  alt=""
                />
              </a>
            </div>
            <div className="w-full flex flex-row">
              <a
                className="w-full ml-5 mr-[10px]"
                href="https://monsstenbraaten.com"
                target="blank"
              >
                <img
                  className="w-full opacity-100 rounded-lg transition-all ease-in-out delay-50 lg:hover:-translate-y-2 lg:hover:scale-110 lg:hover:shadow-lg lg:hover:shadow-black duration-[250ms]"
                  src={Mons}
                  alt=""
                />
              </a>
              <a
                className="w-full mr-5 ml-[10px]"
                href="https://www.sivertamundsen.com"
                target="blank"
              >
                <img
                  className="w-full opacity-100 rounded-lg transition-all ease-in-out delay-50 lg:hover:-translate-y-2 lg:hover:scale-110 lg:hover:shadow-lg lg:hover:shadow-black duration-[250ms]"
                  src={Sivert}
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center py-5 mt-5 mb-10 w-[85%] text-black bg-[#DBC2CF] rounded-lg lg:my-10 lg:w-[30%]">
        <h2 className="text-xl font-bold mb-5 lg:text-xl lg:my-10">
          Yggdrasil
        </h2>
        <img className="mb-5 lg:mb-10" src={Project3} />
        <div className='flex flex-col items-center lg:h-[250px] lg:overflow-y-scroll lg:w-[90%] lg:bg-[#ffffff] lg:rounded lg:mb-10'>
        <p className="w-[80%] mb-5 lg:m-2">
          In our bachelor's thesis, my group and I developed the Yggdrasil
          project, which aimed to increase interest in pilgrimage journeys and
          local history in Gjøvik.
        </p>
        <p className="w-[80%] mb-5 lg:m-2">
          Our solution included an informative website with interesting
          information about pilgrimage journeys in Norway, along with an
          interactive map.
        </p>
        <p className="w-[80%] mb-5 lg:m-2">
          The map featured narratives provided by rurAllure and other points of
          interest that users could generate by filling out a form.
        </p>
        </div>
        <hr className="w-[75%] h-[1px] mx-auto mb-10 bg-[#CAA5B8] border-0 rounded-md" />
        <div className="flex flex-col items-center w-[100%] mb-11">
          <p className="text-lg font-semibold mb-5">Tech</p>
          <div
            id="techBox"
            className="flex flex-col w-[100%] items-center text-lg"
          >
            <div className="flex flex-row justify-around mb-5 w-full">
              <a
                href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
                target="blank"
              >
                <div className="flex flex-col items-center mb-3 lg:hover:scale-110 duration-[250ms]">
                  <i className="devicon-javascript-plain text-4xl lg:text-2xl"></i>
                  <p>JavaScript</p>
                </div>
              </a>
              <a href="https://www.mongodb.com" target="blank">
                <div className="flex flex-col items-center mb-3 lg:hover:scale-110 duration-[250ms]">
                  <i className="devicon-mongodb-plain text-4xl lg:text-2xl"></i>
                  <p>MongoDB</p>
                </div>
              </a>
              <a href="https://expressjs.com" target="blank">
                <div className="flex flex-col items-center mb-3 lg:hover:scale-110 duration-[250ms]">
                  <i className="devicon-express-original text-4xl lg:text-2xl"></i>
                  <p>Express.js</p>
                </div>
              </a>
            </div>
            <div className="flex flex-row justify-around w-full">
              <a href="https://www.figma.com" target="blank">
                <div className="flex flex-col items-center mb-3 lg:hover:scale-110 duration-[250ms]">
                  <i className="devicon-figma-plain text-4xl lg:text-2xl"></i>
                  <p>Figma</p>
                </div>
              </a>
              <a href="https://nodejs.org/en" target="blank">
                <div className="flex flex-col items-center mb-3 lg:hover:scale-110 duration-[250ms]">
                  <i className="devicon-nodejs-plain text-4xl lg:text-2xl"></i>
                  <p>Node.js</p>
                </div>
              </a>
              <a href="https://react.dev" target="blank"><div className="flex flex-col items-center mb-3 lg:hover:scale-110 duration-[250ms]">
              <i className="devicon-react-original text-4xl lg:text-2xl"></i>
              <p>React</p>
            </div></a>
            </div>
          </div>
        </div>
        <hr className="w-[75%] h-[1px] mx-auto mb-10 bg-[#CAA5B8] border-0 rounded-md" />
        <div className="flex flex-col items-center w-[100%] mb-10 lg:w-[75%]">
          <p className="text-lg font-semibold mb-10">Collaborators</p>
          <div className="flex flex-col w-[100%]">
            <div className="w-full flex flex-row">
              <a
                className="w-full ml-5 mr-[10px]"
                href="https://monsstenbraaten.com"
                target="blank"
              >
                <img
                  className="w-full opacity-100 rounded-lg transition-all ease-in-out delay-50 lg:hover:-translate-y-2 lg:hover:scale-110 lg:hover:shadow-lg lg:hover:shadow-black duration-[250ms]"
                  src={Mons}
                  alt=""
                />
              </a>
              <a
                className="w-full mr-5 ml-[10px]"
                href="https://www.sivertamundsen.com"
                target="blank"
              >
                <img
                  className="w-full opacity-100 rounded-lg transition-all ease-in-out delay-50 lg:hover:-translate-y-2 lg:hover:scale-110 lg:hover:shadow-lg lg:hover:shadow-black duration-[250ms]"
                  src={Sivert}
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Projects;
